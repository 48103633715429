import { rawAxios as axios } from "./axios";
import { api } from "./axios.config";

// const API_URL = api.manageApiUrl;

const API_URL = api.manageApiUrl;

const GET_Communities = "/community";

export function getCommunities(searchQuery, communityType) {
    let QUERY = '?';
    if (searchQuery !== "") QUERY += `search_query=${searchQuery}`;
    return axios.get(API_URL + GET_Communities + QUERY);
}
