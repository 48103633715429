import { rawAxios as axios } from "./axios";
import { api } from "./axios.config";

// const API_URL = api.manageApiUrl;

const API_URL = api.manageApiUrl;

const GET_EVENTS = "/hosted-events";
const GET_FEATURED_EVENTS = "/past-events";

export function getEvents(
  limit,
  search_query,
  past_events,
  future_events,
  running_events
) {
  let QUERY = `?limit=${limit}`;
  if (search_query !== "") QUERY += `&search_query=${search_query}`;
  if (past_events !== "") QUERY += `&past_events=${past_events}`;
  if (future_events !== "") QUERY += `&future_events=${future_events}`;
  if (running_events !== "") QUERY += `&running_events=${running_events}`;
  return axios.get(API_URL + GET_EVENTS + QUERY);
}

export function getFeaturedEvents(eventType) {
  const eventTypeQuery = eventType ? eventType : "/featured-events"
  return axios.get(API_URL + eventTypeQuery);
}
