import { rawAxios as axios } from "./axios";
import { api } from "./axios.config";

const API_URL = api.manageApiUrl;

const GET_FEATURED_NUMBERS = "/featured-numbers";
const GET_TESTIMONIALS = "/testimonials";
const GET_PAST_EVENTS = "/past-events";

export function getFeaturedNumbers() {
  return axios.get(API_URL + GET_FEATURED_NUMBERS);
}


export function getTestimonials() {
  return axios.get(API_URL + GET_TESTIMONIALS);
}

export function getPastEvents() {
  return axios.get(API_URL + GET_PAST_EVENTS);
}

