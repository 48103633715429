import React, { useState, useEffect } from 'react'
import { trustedByData, canDos, testimonials, resources } from '../../data/data'
import dynamic from "next/dynamic";
const NewHomeHeader = dynamic(import("./NewHomeHeader"));
const HomeUsage = dynamic(import("./HomeUsage"));
const HomeEvents = dynamic(import("./HomeEvents"));
const TrustedBy = dynamic(import("./TrustedBy"));
const HomeNav = dynamic(import("./HomeNav"));
const HomeFeatures = dynamic(import("./HomeFeatures"));
const HomeBlogs = dynamic(import("./HomeBlogs"));
const HomeTestimonials = dynamic(import("./HomeTestimonials"));
const HomeAwards = dynamic(import("./HomeAwards"));
const HomePrivacy = dynamic(import("./HomePrivacy"));
const HomeFooter = dynamic(import("./HomeFooter"));
const HomeParticipation = dynamic(import("./HomeParticipation"));
const HomeOperations = dynamic(import("./HomeOperations"));
const HomeConferences = dynamic(import("./HomeConferences"));
const HomeCostCalc = dynamic(import("./HomeCostCalc"));
const HomeCommunities = dynamic(import("./HomeCommunities"));
const HomeResources = dynamic(import("./HomeResources"));
import { getCommunities } from '../../crud/communities.curd'
import { errorToaster } from '../../helpers/utils'
import { getFeaturedEvents } from '../../crud/event-listing.crud'
import { getPastEvents, getTestimonials } from '../../crud/landing-page.crud'


const Home = ({ newUserLogin }) => {

  const [communitiesData, setCommunitiesData] = useState([]);
  const [FeaturedEvents, setFeaturedEvents] = useState([]);
  const [loadingFeaturedEvents, setLoadingFeaturedEvents] = useState(false);
  const [HostedEvents, setHostedEvents] = useState([])
  const [TestimonialData, setTestimonialData] = useState(testimonials)


  const getCommunitiesFunction = async () => {
    try {
      const res = await getCommunities();
      setCommunitiesData(res.data)
    } catch (err) {
      errorToaster("Something went wrong");
    }
  };


  const getTestimonialsFunction = async () => {
    try {
      const res = await getTestimonials();
      setTestimonialData(res.data);
    } catch (err) {
      errorToaster("Something went wrong while getting testimonial data");
    }
  };

  const getUpcommingEvents = async () => {
    setLoadingFeaturedEvents(true);
    try {
      const res = await getFeaturedEvents();
      setFeaturedEvents(res.data);
      setLoadingFeaturedEvents(false);
    } catch (err) {
      errorToaster("Something went wrong while getting other events");
      setLoadingFeaturedEvents(false);
    }
  }


  const getHostedEvents = async () => {
    try {
      const res = await getPastEvents();
      setHostedEvents(res.data);
    } catch (err) {
      errorToaster("Something went wrong while getting other events");
    }
  }

  useEffect(() => {
    getCommunitiesFunction()
    getUpcommingEvents()
    getHostedEvents()
    getTestimonialsFunction()
  }, [])


  return (
    <>
      <HomeNav newUserLogin={newUserLogin} />
      <NewHomeHeader />
      <TrustedBy data={trustedByData} />
      <HomeEvents data={FeaturedEvents} loading={loadingFeaturedEvents} />
      <HomeUsage />
      <HomeFeatures features={canDos} />
      <HomeCostCalc />
      <HomeOperations />
      <HomeParticipation />
      {communitiesData.length > 0 ? (
        <HomeCommunities communitiesData={communitiesData} />
      ) : ""}
      <HomeBlogs />
      {/** resources.length ? (
        <HomeResources data={resources} />
      ) : "" */}
      {HostedEvents.length > 0 ? (
        <HomeConferences data={HostedEvents} />
      ) : ""}
      <HomeTestimonials data={TestimonialData} />
      <HomeAwards />
      <HomePrivacy />
      <HomeFooter />
    </>
  )
}

export default Home
